export const AgbModal = () => import('../../components/AgbModal.vue' /* webpackChunkName: "components/agb-modal" */).then(c => wrapFunctional(c.default || c))
export const AngebotsuebersichtApiData = () => import('../../components/AngebotsuebersichtApiData.vue' /* webpackChunkName: "components/angebotsuebersicht-api-data" */).then(c => wrapFunctional(c.default || c))
export const ContactInfo = () => import('../../components/ContactInfo.vue' /* webpackChunkName: "components/contact-info" */).then(c => wrapFunctional(c.default || c))
export const Countdown = () => import('../../components/Countdown.vue' /* webpackChunkName: "components/countdown" */).then(c => wrapFunctional(c.default || c))
export const CovidDisclaimer = () => import('../../components/CovidDisclaimer.vue' /* webpackChunkName: "components/covid-disclaimer" */).then(c => wrapFunctional(c.default || c))
export const CustomerFeedback = () => import('../../components/CustomerFeedback.vue' /* webpackChunkName: "components/customer-feedback" */).then(c => wrapFunctional(c.default || c))
export const DiscountDetails = () => import('../../components/DiscountDetails.vue' /* webpackChunkName: "components/discount-details" */).then(c => wrapFunctional(c.default || c))
export const ItemCards = () => import('../../components/ItemCards.vue' /* webpackChunkName: "components/item-cards" */).then(c => wrapFunctional(c.default || c))
export const Klimaneutral = () => import('../../components/Klimaneutral.vue' /* webpackChunkName: "components/klimaneutral" */).then(c => wrapFunctional(c.default || c))
export const LanguageSwitcher = () => import('../../components/LanguageSwitcher.vue' /* webpackChunkName: "components/language-switcher" */).then(c => wrapFunctional(c.default || c))
export const RadioButtonTab = () => import('../../components/RadioButtonTab.vue' /* webpackChunkName: "components/radio-button-tab" */).then(c => wrapFunctional(c.default || c))
export const Reisepackages = () => import('../../components/Reisepackages.vue' /* webpackChunkName: "components/reisepackages" */).then(c => wrapFunctional(c.default || c))
export const TeilnehmerAngabenForm = () => import('../../components/TeilnehmerAngabenForm.vue' /* webpackChunkName: "components/teilnehmer-angaben-form" */).then(c => wrapFunctional(c.default || c))
export const Transport = () => import('../../components/Transport.vue' /* webpackChunkName: "components/transport" */).then(c => wrapFunctional(c.default || c))
export const VersicherungInformation = () => import('../../components/VersicherungInformation.vue' /* webpackChunkName: "components/versicherung-information" */).then(c => wrapFunctional(c.default || c))
export const AnmeldungAnmeldungresponse = () => import('../../components/anmeldung/Anmeldungresponse.vue' /* webpackChunkName: "components/anmeldung-anmeldungresponse" */).then(c => wrapFunctional(c.default || c))
export const AnmeldungRedirectCard = () => import('../../components/anmeldung/RedirectCard.vue' /* webpackChunkName: "components/anmeldung-redirect-card" */).then(c => wrapFunctional(c.default || c))
export const AnmeldungWelcomePreise = () => import('../../components/anmeldung/WelcomePreise.vue' /* webpackChunkName: "components/anmeldung-welcome-preise" */).then(c => wrapFunctional(c.default || c))
export const ReiseAngebotTemplate = () => import('../../components/reiseTemplates/ReiseAngebotTemplate.vue' /* webpackChunkName: "components/reise-angebot-template" */).then(c => wrapFunctional(c.default || c))
export const ZusatzangebotGreetingsText = () => import('../../components/zusatzangebot/GreetingsText.vue' /* webpackChunkName: "components/zusatzangebot-greetings-text" */).then(c => wrapFunctional(c.default || c))
export const ZusatzangebotItem = () => import('../../components/zusatzangebot/Item.vue' /* webpackChunkName: "components/zusatzangebot-item" */).then(c => wrapFunctional(c.default || c))
export const ZusatzangebotNewOrder = () => import('../../components/zusatzangebot/NewOrder.vue' /* webpackChunkName: "components/zusatzangebot-new-order" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
